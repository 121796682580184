<template>
  <v-card>
    <v-dialog fullscreen v-model="preview_dialog" overlay-color="grey darken-2">
      <div class="site-section-wrapper" style="min-height: 90vh">
        <div class="site-section" style="min-height: 90vh">
          <v-card class="px-8 pt-12 pb-10" style="position: relative; min-height: 90vh">
            <div>
              <v-btn depressed small style="position: absolute; top: 3px; right: 5px" @click="preview_dialog = false">Закрити</v-btn>
            </div>
            <div
                style="font-size: .9rem; color: #4a4a4a; padding-left: 2px; padding-bottom: 4px; text-transform: uppercase; display: flex">
              <div>Новини</div>
              <v-spacer></v-spacer>
              <div>22.06.2021</div>
            </div>
            <v-divider></v-divider>
            <div style="font-size: 1.8rem; text-transform: uppercase; font-weight: 500; padding-bottom: 18px; padding-top: 12px">
              {{ theme }}
            </div>
            <div>
              <v-img
                  :src="getFilePath()"
                  contain
                  height="200px"
              />
            </div>
            <div class="py-6" v-html="compiledMarkdown">
            </div>
          </v-card>
        </div>
      </div>
    </v-dialog>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="12" md="12">
            <v-img
                :src="getFilePath()"
                height="140px"
                max-height="140px"
                id="img"
                :contain="contain"
                aspect-ratio="1"
                @click="clickUpload"
                class="grey lighten-4"
            />
          </v-col>
          <v-col cols="12" md="12" class="d-flex">
            <v-text-field type="text" filled label="Заголовок" v-model="theme"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="theme ? '' : 'req-star'"
                          full-width
                          color="grey"/>
            <v-file-input
                accept="image/*"
                v-model="file"
                filled
                hide-details
                :id="id"
                style="flex: 0 0 100px; display: none"
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea type="text" filled label="Текст новини" v-model="body"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="body ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="preview_dialog = true" class="button-accept" :loading="getModalLoading">
        Перегляд
      </v-btn>
      <v-btn depressed text color="secondary darken-1" @click.stop="crud_item" class="button-accept" :loading="getModalLoading">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {BASE_URL_AXIOS} from '@/utils/axios';
import {CREATE_SITE_NEWS, REMOVE_SITE_NEWS, UPDATE_SITE_NEWS} from "@/store/actions/personalSite/site";
import marked from 'marked'
marked.setOptions({
  silent: true,
  pedantic: false,
  gfm: true,
  breaks: false,
  sanitize: false,
  smartLists: true,
  smartypants: false,
  xhtml: false,
  mangle: true
})

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'news_modal_delete'

export default {
  name: "HWP_ModalNews",
  mixins: [ModalComponentMixin],
  data() {
    return {
      theme: this.item.title,
      body: this.item.body,
      filepath: this.item.filepath,
      file: null,
      menu: false,
      BASE_URL_AXIOS,
      publicPath: process.env.BASE_URL,
      contain: false,
      preview_dialog: false,
      id: Date.now()
    }
  },
  methods: {
    clickUpload() {
      const btn = document.getElementById(`${this.id}`)
      btn.click()
    },
    getFilePath() {
      if (this.file) {
        this.contain = true
        return URL.createObjectURL(this.file)
      } else {
        if (this.filepath) {
          this.contain = true
          return `${BASE_URL_AXIOS}/${this.filepath}`
        } else {
          this.contain = true
          return `${this.publicPath}img/icons/no_image.png`
        }
      }
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.theme = this.item.title
      this.body = this.item.body
      this.filepath = this.item.filepath
      this.file = null
      this.id = Date.now()

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення новини ${this.theme}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Заголовок та Текст - мають бути заповненіі',
          color: 'error lighten-1'
        })
        return
      }

      const formData = new FormData()
      formData.append('title', this.theme)
      formData.append('body', this.body)
      formData.append('filepath', this.filepath)
      if (this.file) {
        formData.append('files', this.file)
      }

      const img = document.getElementById('img').getElementsByClassName('v-image__image')
      const src = img[0].style.backgroundImage

      if (this.isNew) {
        this.$store.dispatch(CREATE_SITE_NEWS, formData)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        formData.append('id_', this.itemId)
        if (src.indexOf('no_image.png') !== -1 && this.filepath) {
          formData.append('file_delete', this.filepath)
        }
        this.$store.dispatch(UPDATE_SITE_NEWS, formData)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.theme = payload.title
              this.body = payload.body
              this.filepath = payload.filepath
              this.fill = null
              this.id = Date.now()
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_SITE_NEWS, this.itemId)
                    .then(ok => {
                      if (ok) {
                        this.closeModal()
                      }
                    })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    compiledMarkdown() {
      if (!this.body) return ''
      return marked(this.body);
    },
  }
}
</script>

<style scoped lang="scss">
  .site-section {
    max-width: 1400px;
    margin: 0 auto;
    padding: 60px 10px;
  }
</style>